import React from 'react'
import TransitionLink from 'gatsby-plugin-transition-link'
import { Logo } from './Logo'
import TableOfContents from './TableOfContents'
import { pageTransitionIn, pageTransitionOut } from '../utils/pageTransition'

const OtherArticles = ({ prev, next, issueSlug }) => {
	return (
		<div className="large-container">
      <TransitionLink className="block w-min my-48 mx-auto" to="/" entry={pageTransitionIn} exit={pageTransitionOut}>
        <Logo className="w-12 h-auto mx-auto text-blue fill-current" />
      </TransitionLink>
			<TableOfContents articles={[prev?.article, next?.article]} tight />
		</div>
	)
}

export default OtherArticles
